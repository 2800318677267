@value _viewportMd: screen and (min-width: 48em);
@value _viewportMenu: screen and (min-width: 62em);

:root {
  --breakpoint-sm: _breakpointSm;
  --breakpoint-md: _breakpointMd;
  --breakpoint-lg: _breakpointLg;
  --breakpoint-xl: _breakpointXl;
}

:export {
  viewportMd: _viewportMd;
  viewportMenu: _viewportMenu;
  breakpointSm: 480px;
  breakpointMd: _breakpointMd;
  breakpointLg: 1024px;
  breakpointXl: 1280px;
}

@custom-media --viewport-sm screen and (min-width: 480px);
@custom-media --viewport-md screen and (min-width: 48em); /* 768px */
@custom-media --viewport-lg screen and (min-width: 1024px);
@custom-media --viewport-xl screen and (min-width: 1280px);
