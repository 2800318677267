:root {
  --font-family-base: 'Universal', sans-serif;

  --font-weight-300: 300;
  --font-weight-400: 400;

  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-24: 24px;
  --font-size-28: 28px;
  --font-size-32: 32px;
  --font-size-38: 38px;
  --font-size-40: 40px;
  --font-size-42: 42px;
  --font-size-45: 45px;
  --font-size-52: 52px;
  --font-size-72: 72px;

  --font-size-unit: 1px;
  --line-height-text: 1.33;
  --font-size-viewport-min: 360;
  --font-size-viewport-max: 1300;

  --font-size-growth: calc(1 / (var(--font-size-viewport-max) - var(--font-size-viewport-min)));
  --m: calc((var(--font-size-growth) * 100vw) - (var(--font-size-growth) * var(--font-size-viewport-min) * var(--font-size-unit)));

  --font-size-12-14: clamp(12px, calc(12px + 2 * var(--m)), 14px);
  --font-size-14-16: clamp(14px, calc(14px + 2 * var(--m)), 16px);
  --font-size-14-18: clamp(14px, calc(14px + 4 * var(--m)), 18px);
  --font-size-16-18: clamp(16px, calc(16px + 2 * var(--m)), 18px);
  --font-size-16-22: clamp(16px, calc(16px + 6 * var(--m)), 22px);
  --font-size-20-26: clamp(20px, calc(20px + 6 * var(--m)), 26px);
  --font-size-22-38: clamp(22px, calc(22px + 16 * var(--m)), 38px);
  --font-size-24-42: clamp(24px, calc(24px + 18 * var(--m)), 42px);
  --font-size-28-38: clamp(28px, calc(28px + 10 * var(--m)), 38px);
  --font-size-28-42: clamp(28px, calc(28px + 14 * var(--m)), 42px);
  --font-size-32-42: clamp(32px, calc(32px + 10 * var(--m)), 42px);
  --font-size-32-52: clamp(32px, calc(32px + 20 * var(--m)), 52px);
  --font-size-38-52: clamp(38px, calc(38px + 12 * var(--m)), 52px);
  --font-size-38-72: clamp(38px, calc(38px + 34 * var(--m)), 72px);
  --font-size-52-72: clamp(52px, calc(52px + 20 * var(--m)), 72px);
  --font-size-72-112: clamp(72px, calc(72px + 40 * var(--m)), 112px);
}
