:root {
  --color-gray-100: #f8f8f8;
  --color-gray-700: #4d4d4d;
  --color-gray-800: #1e1e1e;
  --color-gray-900: #1c1c1c;

  --color-white: #fff;

  --color-blue-300: #00a1dd;
  --color-rgb-blue-300: 0, 161, 221;
  --color-blue-500: #003146;
  --color-rgb-blue-500: 0, 49, 70;
  --color-blue-900: #002433;

  --gradient-to-top-left: linear-gradient(to top left, rgba(var(--color-rgb-blue-300), 0.25) 0%, rgba(var(--color-rgb-blue-300), 1) 100%);
  --gradient-to-bottom-left: linear-gradient(to bottom left, rgba(var(--color-rgb-blue-300), 0.25) 0%, rgba(var(--color-rgb-blue-300), 1) 100%);
  --gradient-to-bottom-right: linear-gradient(to bottom right, rgba(var(--color-rgb-blue-300), 0.25) 0%, rgba(var(--color-rgb-blue-300), 1) 100%);
  --gradient-to-bottom: linear-gradient(to bottom, rgba(var(--color-rgb-blue-500), 1) 0%, rgba(var(--color-rgb-blue-500), 0) 100%);
  --gradient-to-left: linear-gradient(to left, rgba(var(--color-rgb-blue-500), 1) 0%, rgba(var(--color-rgb-blue-300), 1) 100%);
  --gradient-to-top: linear-gradient(to top, rgba(var(--color-rgb-blue-500), 1) 0%, rgba(var(--color-rgb-blue-500), 0) 100%);
  --gradient-from-transparent-to-right: linear-gradient(to left, var(--color-blue-500) 0%, rgba(0, 0, 0, 0) 100%);
}
